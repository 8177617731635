import React from 'react';

function App() {
  return (
    <div>
      <h1>ANKOVAR</h1>
      <p>Build The Right Thing</p>
      <ul>
        <li>Understand the job to be done</li>
        <li>Uncover unmet needs</li>
        <li>Segment your market</li>
      </ul>
    </div>
  );
}

export default App;
